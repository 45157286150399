@font-face {
  font-family: "GT-Walsheim-Bold";
  src: local("GTWalsheim-Bold"), url("./fonts/GTWalsheim-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "GT-Walsheim-Regular";
  src: local("GTWalsheim-Regular"), url("./fonts/GTWalsheim-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "GT Walsheim";
  src: local("GTWalsheim"), url("./fonts/GTWalsheim.ttf") format("truetype");
}

body {
  font-family: "GT Walsheim", Sans-serif !important;
  font-style:normal !important;
  font-weight:normal !important;
  font-size:16px !important;
  line-height:32px !important;
  -webkit-font-smoothing: antialiased;
}

h1, h2 {
  font-family: "GT-Walsheim-Bold", sans-serif !important;
  line-height: 1.5em !important;

}



