.bg-biz {
  background-image: url("../../assets/bg-profile.png");
  height: 13rem;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.bg-icon-info {
  background-color: #e9f3ff;
}
.title-container {
  margin-top: 4em;
}
.profile-pic {
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 20px solid rgb(89 227 167 / 15%);
  border-radius: 50%;
}
.profile-pic img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  object-position: top;
  border: 5px solid #59e3a7;
  border-radius: 50%
}
.floating-input {
  border: 2px solid #e9f4ff;
  border-radius: 5px;
  position: relative;
  padding: 15px;
}
.floating-label {
  position: absolute;
  top: -10px;
  background: #fff;
  margin: 0px 20px;
  font-size: 16px;
  font-family: sans-serif;
  padding: 0px 15px;
  color: #000;
  font-weight: bold;
}

.modal-title {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  color: #ffba6e;
}

.block-scroll {
  overflow: hidden;
}

.exchange-sheet-title {
  color: #000;
  text-align: center;
  position: relative;
  top: 15px;
}
.info-title {
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #143047;
  margin-bottom: 0.5rem;
}
.info-content {
  font-size: 15px;
  line-height: normal;
  text-align: left;
  font-weight: normal;
  color: #637787;
  margin-bottom: 1rem;
}

.info-icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-bottom-sheet {
  z-index: 8;
  display: none;
  background-color: "white";
}

.custom-bottom-sheet__body {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

@media only screen and (max-width: 767px) {
  .custom-bottom-sheet {
    display: unset;
  }
}
.name-title {
  display: block;
  color: #ffba6e;
  font-size: 18px;
  font-weight: bold;
  flex-direction: column;
  margin: 0;
}
.position {
  font-weight: 700;
  font-size: 12px;
}
